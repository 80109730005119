import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMask from 'v-mask'

//import DisableAutocomplete from 'vue-disable-autocomplete';

import './registerServiceWorker'
axios.defaults.baseURL = process.env.VUE_APP_API
if(process.env.VUE_BUILD_TYPE == 'prod') Vue.config.devtools = false
else Vue.config.devtools = true
Vue.config.productionTip = false
Vue.config.performance = true
Vue.config.silent = true
Vue.use(VueAxios, axios)
Vue.use(VueMask);
//Vue.use(DisableAutocomplete)
new Vue({
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')