import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';
import '@mdi/font/css/materialdesignicons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#1565C0',
                secondary: '#e3edf8',
                accent: '#1565C0',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#f1f3f9',
                title: '#757575'
            },
            dark: {
                primary: '#1565C0',
                secondary: '#e3edf8',
                accent: '#1565C0',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#212121',
                background2: '#121212'
            },
        },
    },
    lang: {
        locales: { es },
        current: 'es',
    },
});