import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from '@/plugins/vuetify'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackbar: {
            status: false,
            text: null,
            time: -1,
            color: null,
            icon: null,
            id: null
        },
        componente: {
            principal: 'Login',
            secundario: null
        },
        dialog: {
            calendario: false,
            calendario_resumen : false,
            servicio: false,
            turno: false,
            usuario: false,
            asignar: false,
            editar: false,
            ils: false,
            turno_edit: false,
            turno_lista: false,
            personal_lista: false,
            personal: false,
            turno_nulo: false,
            perfil_edit: false,
            contacto_lista: false,
            tabla_asignaciones: false,
            itinerario_servicio: false,
            planilla_obs: false,
            planilla_il: false,
            perfil_pass: false
        },
        usuario: {
            nombre: null,
            tipo: null,
            token: null
        },
        sistema: {
            hora: '--:--:--',
            diff: 0,
            hora_interval: null,
            app_version: JSON.parse(unescape(process.env.APP_VERSION || '%7B%7D')).version,
            a2hs: {
                event: false,
                dialog: false,
                ios: false
            },
            tema: 'light',
            exit_app : false
        },
        update: {
            turno: false,
            calendario: false,
            buscar: false,
            itinerario: false,
            planilla: false,
            fecha: null,
            perfil: false,
            usuario: false       
        }
    },
    mutations: {
        ShowSnackBar(state, payload) { 
            state.snackbar.id = (Math.random() + 1).toString(36).substring(7)
            state.snackbar.text = payload.text
            state.snackbar.time = payload.time
            state.snackbar.color = payload.color
            state.snackbar.icon = payload.icon
            state.snackbar.status = true
        },
        LogOut(state) {
            localStorage.clear()
            state.sistema.tema = 'light'
            Vuetify.framework.theme.dark = false
            state.componente.principal = 'Login'
            window.history.pushState('Login', 'Login', null)

        },
        UserView(state) {
            let app_data = JSON.parse(localStorage.getItem("app_data"))
            if (app_data) {
                if(app_data.tema) state.sistema.tema = app_data.tema
                if (app_data.token) {
                    state.componente.principal = 'Home'
                    if (app_data.token) {
                        state.usuario.token = app_data.token
                        switch (app_data.tipo) {
                            case 'ft':
                                state.componente.secundario = 'Usuario'
                                state.usuario.tipo = 'Full Time'
                                break
                            case 'pt':
                                state.componente.secundario = 'Usuario'
                                state.usuario.tipo = 'Part Time 80'
                                break
                            case 'pts':
                                state.componente.secundario = 'Usuario'
                                state.usuario.tipo = 'Part Time 120'
                                break
                            case 'il':
                                state.componente.secundario = 'Planilla'
                                state.usuario.tipo = 'Inspector Linea'
                                break
                            case 'ilt':
                                state.componente.secundario = 'Planilla'
                                state.usuario.tipo = 'Inspector Linea'
                                break
                            case 'etl':
                                state.componente.secundario = 'Planilla'
                                state.usuario.tipo = 'Encargado Terminal Limache'
                                break
                            case 'ctc':
                                state.componente.secundario = 'Itinerario'
                                state.usuario.tipo = 'Controlador Trafico'
                                break
                            case 'jt':
                                state.componente.secundario = 'Planilla'
                                state.usuario.tipo = 'Jefe Trafico'
                                break
                            case 'adm':
                                state.componente.secundario = 'Planilla'
                                state.usuario.tipo = 'Administrador'
                                break
                            case 'ger':
                                state.componente.secundario = 'Planilla'
                                state.usuario.tipo = 'Gerencia'
                                break
                            case 'otr':
                                state.componente.secundario = 'Itinerario'
                                state.usuario.tipo = 'Otro'
                                break
                            default:
                                localStorage.clear()
                                state.componente.principal = 'Login'
                                break
                        }
                        state.usuario.nombre = app_data.nombre
                    }
                }
            }
        },
        CloseAllDialog(state) {
            state.dialog = {
                calenario: false,
                servicio: false,
                turno: false,
                usuario: false,
                asignar: false,
                editar: false,
                ils: false,
                turno_edit: false,
                turno_lista: false
            }
        },
        ErrorRequest(state, mensaje) {
            if (mensaje.mensaje) {
                if (mensaje.code > 0) {
                    if (mensaje.code == 2) {
                        this.commit("ShowSnackBar", {
                            text: mensaje.mensaje,
                            time: 3000,
                            icon: 'mdi-alert-circle-outline',
                            color: 'red'
                        })
                    }else{
                        this.commit("ShowSnackBar", {
                            text: mensaje.mensaje + '. Cerrando sesión',
                            time: 3000,
                            icon: 'mdi-alert-circle-outline',
                            color: 'red'
                        })
                    }
                    this.commit("LogOut")
                } else {
                    let color = 'green'
                    if (mensaje.status) color = 'red'
                    this.commit("ShowSnackBar", {
                        text: mensaje.mensaje,
                        time: 3000,
                        color,
                        icon: 'mdi-check-circle-outline'
                    })
                }
            } else {
                let color = 'red'
                if (mensaje.response) {
                    this.commit("ShowSnackBar", {
                        text: 'Respuesta inesperada por el Servidor',
                        time: 3000,
                        color,
                        icon: 'mdi-alert-circle-outline'
                    })
                }
                if (mensaje.request) {
                    this.commit("ShowSnackBar", {
                        text: 'Error de Red o Servidor',
                        time: 3000,
                        color,
                        icon: 'mdi-alert-circle-outline'
                    })
                } else {
                    this.commit("ShowSnackBar", {
                        text: mensaje,
                        time: 3000,
                        color,
                        icon: 'mdi-alert-circle-outline'
                    })
                }
            }
        },
        UpdateComponente(state, componente) {
            if (componente) state.update[componente] = true
        },
        OpenDialog(state, dialog) {
            state.dialog[`${dialog}`] = true
            state.sistema.exit_app = false
        },
        CloseDialog(state, dialog) {
            state.dialog[`${dialog}`] = false
        }
    },
    actions: {},
    modules: {}
})