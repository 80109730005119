<template>
<v-app id="v_app">
    <v-main id="main_app">
        <!--<v-card flat color="primary" width="50%" height="50%" id="#cardbkleft" class="rounded-xl"></v-card>-->
        <transition name="fade">
            <div id="back_color" v-if="!TemaDark"/>
            <div id="back_color_bk" v-else/>
        </transition>
        <transition name="fade" mode="out-in" appear>
            <component :is="componente.principal" />
        </transition>
        <v-footer padless app color="transparent" inset fixed v-if="componente.principal == 'Login'">
            <v-col class="text-center" cols="12">
                <span class="grey--text trenos-footer"> Hecho con <v-icon color="red darken-2" size="20"> mdi-heart</v-icon> por  <strong>DmoreN</strong></span>
            </v-col>
        </v-footer>
    </v-main>
    <v-snackbar v-model="snackbar.status" :timeout="snackbar.time" :color="snackbar.color" :class="SnackLugar" elevation="0" transition="fade" rounded="lg" @click.native="snackbar.status = false">
        <v-icon size="18" style="margin-bottom: 3px;">{{snackbar.icon}}</v-icon>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }" v-if="$vuetify.breakpoint.mdAndUp">
            <v-btn icon dark v-bind="attrs" @click="snackbar.status = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
    <div class="d-flex justify-center">
        <v-alert color="warning" v-model="swupdate.status" class="alert-update rounded-lg mt-5" tile transition="slide-y-transition" max-width="350">
            <v-row>
                <v-col class="px-1 pb-0 text-center">
                    <v-avatar color="transparent">
                        <v-icon dark size="55">
                            mdi-download-circle-outline
                        </v-icon>
                    </v-avatar>
                    <p class="white--text mt-3 trenos-title-toolbar mb-0">Actualizacion instalada!</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col >
                    <v-btn block dark @click="RefrescarApp" depressed color="grey darken-2">Aplicar</v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</v-app>
</template>

<script>
import dayjs from "dayjs"
import { mapState, mapMutations } from 'vuex'
var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)
require('dayjs/locale/es')
dayjs.locale('es')
export default {
    name: 'App',
    components: {
        Login: () => import('@/components/Login'),
        Home: () => import('@/components/Home')
    },
    data: () => ({
        swupdate: {
            status: false,
            reg: null,
            refrescando: false
        }
    }),
    computed: {
        ...mapState(['snackbar', 'componente', 'sistema','dialog']),
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        SnackId(){
            return this.snackbar.id
        },
        SnackLugar(){
            let lugar = 'snack-class-desktop'
            if(!this.$vuetify.breakpoint.mdAndUp) lugar = 'snack-class-mobile'
            return lugar
        }
    },
    methods: {
        ...mapMutations(['LogOut', 'UserView','CloseDialog','UpdateComponente','ErrorRequest']),
        ActualizacionDisponible(event) {
            this.swupdate.reg = event.detail
            this.swupdate.status = true
        },

        // Called when the user accepts the update
        RefrescarApp() {
            this.swupdate.status = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.swupdate.reg || !this.swupdate.reg.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            this.swupdate.reg.waiting.postMessage({type: 'SKIP_WAITING'})
            console.log(this.swupdate.reg.waiting)
        },
        Navegacion(){
            if(Object.keys(this.dialog).find(key => this.dialog[key] === true)){
                let vista = this.componente.secundario
                switch (vista) {
                    case 'Usuario':
                        if(this.dialog.turno && !this.dialog.servicio) this.CloseDialog('turno')
                        if(this.dialog.turno && this.dialog.servicio) this.CloseDialog('servicio')
                        if(!this.dialog.turno && this.dialog.servicio) this.CloseDialog('servicio')
                        if(this.dialog.calendario_resumen) this.CloseDialog('calendario_resumen')
                        break
                    case 'Buscar':
                        if(this.dialog.turno_lista){
                            if(this.dialog.turno){
                                this.CloseDialog('turno')
                                break
                            }else{
                                this.CloseDialog('turno_lista')
                                break
                            }
                        }else{
                            if(this.dialog.turno){
                                this.CloseDialog('turno')
                                break
                            }
                        }
                        if(this.dialog.personal_lista){
                            if(this.dialog.usuario){
                                this.CloseDialog('usuario')
                                break
                            }else{
                                this.CloseDialog('personal_lista')
                                break
                            }
                        }else{
                            if(this.dialog.usuario){
                                this.CloseDialog('usuario')
                                break
                            }
                        }
                        if(this.dialog.contacto_lista) this.CloseDialog('contacto_lista')
                        break
                    case 'Itinerario':
                        if(this.dialog.itinerario_servicio) this.CloseDialog('itinerario_servicio')
                        break
                    case 'Planilla':
                        if(this.dialog.planilla_obs) this.CloseDialog('planilla_obs')
                        if(this.dialog.planilla_il) this.CloseDialog('planilla_il')
                        if(this.dialog.turno) this.CloseDialog('turno')
                        break
                    case 'Perfil':
                        if(this.dialog.perfil_pass) this.CloseDialog('perfil_pass')
                        if(this.dialog.perfil_edit) this.CloseDialog('perfil_edit')
                        break
                    default:
                        break
                }
                window.history.pushState({}, '')
            }          
        },
        AppVisible(){
            if (document.visibilityState === "visible"){
                if(this.componente.secundario == 'Usuario') { this.UpdateComponente('usuario') }
                clearInterval(this.sistema.hora_interval)
                this.sistema.hora = dayjs().add(this.sistema.diff, 'seconds').format('HH:mm:ss')
                this.sistema.hora_interval = setInterval(() => { this.sistema.hora = dayjs().add(this.sistema.diff, 'seconds').format('HH:mm:ss')}, 1000)
            } 
        },
    },
    watch: {
        SnackId: function (val) {
            if(val){
                this.snackbar.status = false
                setTimeout(() => {  this.snackbar.status = true }, 200)
            }
        }
    },
    created() {
        window.history.pushState({}, '') //Genera un historial al abrir la app
        //Define el tema de color
        let datos = JSON.parse(localStorage.getItem("app_data"))
        if(datos && datos.tema){
            switch (datos.tema) {
                case 'light':
                    this.$vuetify.theme.dark = false
                    document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#1565C0')
                    break
                case 'dark':
                    this.$vuetify.theme.dark = true
                    document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#121212')
                    break
                case 'system':
                    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
                        this.$vuetify.theme.dark = true
                        document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#121212')
                    }
                    if(window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches){
                        this.$vuetify.theme.dark = false
                        document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#1565C0')
                    }
                    break
            }
        }
        
        //Define la vista a mostrar
        this.UserView()
        if(navigator.serviceWorker){
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                // We'll also need to add 'refreshing' to our data originally set to false.
                if (this.refrescando) return
                this.refrescando = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        }
        //Comprueba si existen actualizaciones 
        document.addEventListener('swUpdated', this.ActualizacionDisponible, { once: true })
        //Comprueba si la webapp está instalada
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault()
            this.sistema.a2hs.event = event
        })
        //Detectar si es ios u otro
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase()
            return /iphone|ipad|ipod/.test(userAgent)
        }
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)
        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
            this.sistema.a2hs.event = true
            this.sistema.a2hs.ios = true
            //console.log('ios detected')
        }
        //Detecta si la app está instada
        window.addEventListener('appinstalled', () => {
            // Hide the app-provided install promotion
            this.sistema.a2hs.dialog = false
            // Clear the deferredPrompt so it can be garbage collected
            this.sistema.a2hs.event = null
            // Optionally, send analytics event to indicate successful install
        })
        
        window.addEventListener( 'popstate', this.Navegacion )
        window.addEventListener( 'resume', this.AppVisible ) //Detecta cuando el usuario vuelve a ver la app (cambio de aplicacion)
        window.addEventListener( 'visibilitychange', this.AppVisible ) //Detecta cuando el usuario vuelve a ver la app (cambio de aplicacion)
    }
}
</script>

<style>

@font-face {
    font-family: "Product Sans";
    src: url('https://fonts.gstatic.com/s/productsans/v7/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2') format('woff2');
}

html { 
    overflow-y: auto !important;
    scroll-behavior: smooth;
    overscroll-behavior-y: none !important;
}


#cardbkleft {
    width: 42%;
    height: 69%;
    position: absolute;
    transform: rotate(35deg);
    left: -30vh;
}

#main_app {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 64px !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .1s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 95% !important;
}

.v-dialog {
    border-radius: 8px !important;
    margin: 8px !important;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media only screen and (max-width: 960px) {
    .v-dialog__content{
        align-items: flex-end !important;
        bottom: calc(5vh + env(safe-area-inset-bottom)) !important; 
    }
}   

.v-tabs-slider-wrapper {
    text-align: -webkit-center;
}

.v-tabs-slider {
    max-width: 50%;
}

.v-btn:hover:after {
    background-color: transparent;
}

.theme--light.v-application {
    background: #f1f3f9 !important;
    color: rgba(0, 0, 0, 0.87);
}

.card-shadow {
    box-shadow: 5px 5px 25px #e6e6e6, -5px -5px 25px #ffffff !important;
}

#back_color {
    width: 110%;
    height: 110%;
    position: fixed;
    top: -2px;
    left: -2px;
    background-color: var(--v-primary-base);
    background: url(../public/img/wave_1.svg) no-repeat center top;
    background-size: cover;
    transform: rotate(180deg);
}

#back_color_bk {
    width: 110%;
    height: 110%;
    position: fixed;
    top: -2px;
    left: -2px;
    background-color: var(--v-primary-base);
    background: url(../public/img/wave_1_bk.svg) no-repeat center top;
    background-size: cover;
    transform: rotate(180deg);
}

.v-tabs-slider {
    max-width: 34% !important;
}

.v-tabs-slider-wrapper {
    height: 2px !important;
}

body {
    background-color: var(--v-background-base);
}

.alert-update {
    position: fixed !important;
    top: 0px;
    z-index: 10;
    width: 100%;
    padding-top: calc(16px + env(safe-area-inset-top)) !important
}

.v-alert--prominent .v-alert__icon {
    min-width: 30px !important;
}

.v-expansion-panel-content__wrap {
    padding: 0 !important;
}

.v-btn:before {
    background-color: transparent !important;
}

.v-card:before {
    background-color: transparent !important;
}

.v-tab--active.v-tab:not(:focus)::before {
    opacity: 0 !important;
}

.snack-class-desktop {
    padding-bottom: calc(56px + env(safe-area-inset-bottom)) !important
}

.snack-class-mobile {
    top: 0 !important;
    padding-top: calc(10px + env(safe-area-inset-top)) !important;
    display: block;
}

.trenos-title-toolbar {
    font-family: 'Product Sans' !important;
    font-size: 1.5rem;
    
}

.trenos-title-card {
    font-family: 'Product Sans' !important;
    font-size: 1rem;
}

.v-date-picker-table {
    position: relative;
    padding: 12px 12px !important;
    height: max-content !important;
}

.v-picker__actions.v-card__actions.v-picker__actions--no-title{
    padding-top: 2px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
}

.trenos-tab{
    font-family: 'Product Sans' !important;
    font-size: 2rem;
}

.trenos-footer{
    font-family: 'Product Sans' !important;
    font-size: .9rem;
}

.v-app-bar.v-app-bar--fixed {
    z-index: 4 !important;
}

.v-toolbar__content .v-btn.v-btn--icon.v-size--default, .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
    height: 40px !important;
    width: 40px !important;
}

.toolbar-menu-btn{
    height: 35px !important;
    width: 35px !important;
}

.v-toolbar__title {
    font-size: 1.2rem !important;
}

.v-tab:before {
    border-radius: 8px !important;
}

.v-menu__content{
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 8px 10px 1px rgb(0 0 0 / 0%), 0px 3px 14px 2px rgb(0 0 0 / 11%) !important;
}

@media only screen and (min-width: 961px) {
    ::-webkit-scrollbar {
    height: .5rem!important;
    width: .5rem!important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    --tw-bg-opacity: 1!important;
        background-color: #f5f5f5 !important;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    border-radius: .25rem!important;
    --tw-bg-opacity: 1!important;
    background-color: rgba(156,163,175,var(--tw-bg-opacity))!important;
    }

}

.theme--dark.v-toolbar.v-sheet {
    background-color: #1E1E20;
}


.theme--dark.v-card {
    background-color: #272727 !important;
    color: #FFFFFF;
}

.theme--dark.v-list {
    background-color: #272727 !important;
    color: #FFFFFF;
}


.theme--dark.v-data-table {
    background-color: #272727 !important;
    color: #FFFFFF;
}

.theme--dark.v-tabs > .v-tabs-bar {
    background-color: #272727 !important;
}

.theme--dark.card-title{
    color: var(--grey-lighten-3) !important;
}

.theme--light.card-title{
    color: var(--grey darken-1) !important;
}

.theme--dark.v-card>.v-picker__actions.v-card__actions.v-picker__actions--no-title{
    background-color: #424242 !important;
}


.theme--dark.v-skeleton-loader .v-skeleton-loader__actions, .theme--dark.v-skeleton-loader .v-skeleton-loader__article, .theme--dark.v-skeleton-loader .v-skeleton-loader__card-heading, .theme--dark.v-skeleton-loader .v-skeleton-loader__card-text, .theme--dark.v-skeleton-loader .v-skeleton-loader__date-picker, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-text, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-two-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-three-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-heading, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-thead, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-tbody, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-tfoot {
    background: #272727 !important;
}

.icon_btn_picker{
    margin-top: 2px !important;
}

.theme--dark.v-application {
    background: #212121 !important;
    color: #FFFFFF;
}

.bk-dark-color{
    background-color: #121212 !important;
}

.v-timeline-item__dot{
    box-shadow: none !important;
}

v-overlay--active {
  touch-action: none;
}

.v-chip--clickable:active {
    box-shadow: none !important;
}

/*.v-ripple__container {
    width: 101% !important;
    height: 101% !important;
}*/

</style>